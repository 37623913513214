<template>
    <div class="chat-window" @click.self="$emit('close')">
        <transition name="bounce">
            <ChatDialog
                v-if="show"
                :responseId="responseId"
                :setRoomId="setRoomId"
                @close="$emit('close')"/>
        </transition>
    </div>
</template>

<script setup>
import {ref, onMounted, onDeactivated, onUnmounted} from "vue"
import ChatDialog from './ChatDialog'

defineProps({
    responseId: Number,
    setRoomId: Number
})

const show = ref(false);
onMounted(() => {
    show.value = true
    document.body.style.overflow = 'hidden'
})

onUnmounted(() => document.body.style.overflow = '')
</script>

<style scoped lang="scss">
.chat-window {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(#000, 0.32);
    z-index: 100;
}
</style>
