<template>
    <div class="chat-not-selected">
        <div class="chat-not-selected__content">
            <img src="@/assets/images/icons/message-cricle.svg" class="chat-not-selected__icon" alt="message">
            <h3 class="chat-not-selected__title">Выберите диалог</h3>
            <p class="chat-not-selected__text">Выберите диалог из списка диалогов слева</p>
        </div>
    </div>
</template>

<style scoped lang="scss">
.chat-not-selected {
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        margin-top: -50px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__icon {
        width: 56px;
    }

    &__title {
        margin-top: 12px;
        font-size: 20px;
        font-weight: 600;
    }

    &__text {
        max-width: 344px;
        margin-top: 12px;
        text-align: center;
        font-size: 16px;
    }
}
</style>
