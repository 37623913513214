import api from "./api";

const chat = {
    getRooms(id) {
        return api.get(`/chat/${id}/rooms`);
    },
    getRoomView(chatId, roomId) {
        return api.get(`/chat/${chatId}/rooms/${roomId}`);
    },
    postFile(chatId, roomId, file) {
        return api.post(`/chat/${chatId}/rooms/${roomId}/send-file`, file);
    }
};

export default chat;
