<template>
    <div class="error-popup">
        <div @click="emit('repeat')" class="error-popup__btn">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.9997 5.4209V10.0724H15.3481" stroke="#0D5FCB" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M18.0541 13.949C17.5502 15.3754 16.5963 16.5994 15.3362 17.4365C14.0761 18.2736 12.5781 18.6785 11.0678 18.5901C9.55762 18.5018 8.11702 17.925 6.96314 16.9466C5.80925 15.9683 5.00459 14.6415 4.67042 13.166C4.33624 11.6906 4.49064 10.1465 5.11037 8.76648C5.73009 7.38644 6.78156 6.2452 8.10633 5.51474C9.43109 4.78427 10.9574 4.50416 12.4552 4.71662C13.953 4.92907 15.3412 5.62257 16.4106 6.69262L20 10.0727" stroke="#0D5FCB" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <p class="error-popup__btn-label">Повторить отправку</p>
        </div>
        <div @click="emit('remove')" class="error-popup__btn">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 7.33398H6.55556H19" stroke="#DD4646" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.4446 7.33377V18.2227C17.4446 18.6352 17.2807 19.0309 16.9889 19.3226C16.6972 19.6143 16.3016 19.7782 15.889 19.7782H8.11122C7.69866 19.7782 7.303 19.6143 7.01128 19.3226C6.71955 19.0309 6.55566 18.6352 6.55566 18.2227V7.33377M8.889 7.33377V5.77821C8.889 5.36565 9.05289 4.96999 9.34461 4.67827C9.63633 4.38654 10.032 4.22266 10.4446 4.22266H13.5557C13.9682 4.22266 14.3639 4.38654 14.6556 4.67827C14.9473 4.96999 15.1112 5.36565 15.1112 5.77821V7.33377" stroke="#DD4646" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <p class="error-popup__btn-label">Удалить сообщение</p>
        </div>
    </div>
</template>

<script setup>
import {onMounted} from "vue";

const emit = defineEmits(['close', 'remove', 'repeat'])

onMounted(() => {
    document.addEventListener('click', (e) => {
        if(!e.target.closest('.error-popup') && !e.target.closest('.chat-messages__message-block-btn')) {
            emit('close')
        }
    })
})
</script>

<style scoped lang="scss">
.error-popup {
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 2px 4px 24px rgba(0, 0, 0, 0.12);

    &__btn {
        padding: 4px 8px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__btn-label {
        margin-left: 4px;
        font-size: 14px;
        font-weight: 500;
    }

    &__btn {
        &:first-child {
            color: $blue;
        }

        &:last-child {
            margin-top: 8px;
            color: $red;
        }
    }
}
</style>
