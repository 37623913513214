<template>
    <div class="chat">
        <div class="chat__header">
            <p class="chat__header-title">Чаты</p>
            <div class="chat__header-close" @click="close">
                <img src="@/assets/images/icons/x.svg" alt="close">
            </div>
        </div>

        <div class="chat__body">
            <div class="chat__chat-list">
                <RoomChat
                    v-for="room in rooms"
                    :id="room.room_id"
                    :room="room"
                    :active="activeRoom === room.room_id"
                    @click="openRoom(room.room_id)"
                    class="chat__available-chat"/>
            </div>
            <ChatNotSelected
                v-if="!activeRoom"
                class="chat__empty"/>

            <ChatMessages
                v-else-if="activeRoom && messages.messages"
                :messages="messages.messages"
                :room="messages.room"
                @sendMessage="sendMessage"
                @sendFile="sendFile"
                @toggleChat="toggleChat"
                @repeatMessage="repeatMessage"
                @removeMessage="removeMessage"
                class="chat__messages"/>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import sockets from "@/api/sockets";
import chat from "@/api/chat";
import RoomChat from './RoomChat'
import ChatMessages from './ChatMessages'
import ChatNotSelected from './ChatNotSelected'

const props = defineProps({
    responseId: Number,
    setRoomId: Number
})

const emit = defineEmits(['close'])

const activeRoom = ref(null)

const rooms = ref([])
const messages = ref({})
let socket = null

const socketConnection = () => {
    socket = sockets.socketConnection()

    socket.onopen = () => {
        console.log('Соединение успешно установлено');
    }
    socket.onerror = () => {
        console.log('Ошибка подключения');
    }
    socket.onmessage = async (data) => {
        let response = await JSON.parse(data.data);
        if (response.data.room_id === activeRoom.value) {
            await messages.value.messages.push(response)

            await getRoomView(activeRoom.value)
        } else {
            await getRooms()
        }
    }
    socket.onclose = function (e) {
        console.log('Соединение прервано');
    };
}
const sendMessage = (message) => {
    if (!navigator.onLine) {
        const date = new Date()
        const time = `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`

        messages.value.messages.push({
            type: "MESSAGE",
            offline: true,
            data: {
                room_id: activeRoom.value,
                message_code: generateCode(),
                message_type: "TEXT",
                message_text: message,
                message_attachment: null,
                new_messages_count_in_room: 1,
                time: time,
                is_me_sender: true,
                author: ""
            }
        })
    } else {
        const body = {
            event: "SEND_MESSAGE",
            message_code: generateCode(),
            message: message,
            room_id: activeRoom.value
        }

        socket.send(JSON.stringify(body))
    }
}

const sendFile = async (file) => {
    if (!navigator.onLine) {
        const date = new Date()
        const time = `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
        const fileInfo = file.get('attachment')

        let reader = new FileReader();
        reader.onload = e => {
            messages.value.messages.push({
                type: "MESSAGE",
                offline: true,
                data: {
                    room_id: activeRoom.value,
                    message_code: generateCode(),
                    message_type: "FILE",
                    message_text: '',
                    message_attachment: {
                        file: file,
                        name: fileInfo.name,
                        path: e.target.result,
                        size: `${fileInfo.size / 1000} КБ`,
                        type: fileInfo.type === 'application/pdf' ? 'PDF_DOCUMENT' : 'IMAGE'
                    },
                    new_messages_count_in_room: 1,
                    time: time,
                    is_me_sender: true,
                    author: ""
                }
            })
        }
        reader.readAsDataURL(fileInfo);
    } else {
        const date = new Date()
        const time = `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
        const fileInfo = file.get('attachment')
        const messageCode = generateCode()

        let reader = new FileReader();
        reader.onload = e => {
            messages.value.messages.push({
                type: "MESSAGE",
                loads: true,
                data: {
                    room_id: activeRoom.value,
                    message_code: messageCode,
                    message_type: "FILE",
                    message_text: '',
                    message_attachment: {
                        file: file,
                        name: fileInfo.name,
                        path: e.target.result,
                        size: `${fileInfo.size / 1000} КБ`,
                        type: fileInfo.type === 'application/pdf' ? 'PDF_DOCUMENT' : 'IMAGE'
                    },
                    new_messages_count_in_room: 1,
                    time: time,
                    is_me_sender: true,
                    author: ""
                }
            })
        }
        reader.readAsDataURL(fileInfo);
        const response = await chat.postFile(props.responseId, activeRoom.value, file)
        messages.value.messages = messages.value.messages.filter(message => message.data.message_code !== messageCode)
    }
}

const toggleChat = async () => {
    const body = {
        event: "CONTROL_ROOM",
        room_id: activeRoom.value
    }
    await socket.send(JSON.stringify(body));
    await getRoomView(activeRoom.value)
}

const getRooms = async () => {
    const response = await chat.getRooms(props.responseId)
    rooms.value = response.rooms
}
const getRoomView = async (roomId) => {
    messages.value = await chat.getRoomView(props.responseId, roomId)
    await getRooms()
}
const openRoom = (id) => {
    if (activeRoom.value === id) return
    messages.value = {}
    activeRoom.value = id
    getRoomView(id)
}

const repeatMessage = async (msg) => {
    if (navigator.onLine) {
        if (msg.message_type === 'TEXT') {
            messages.value.messages = messages.value.messages.filter(message => message.data.message_code !== msg.message_code)
            const body = {
                event: "SEND_MESSAGE",
                message_code: `${generateCode()}`,
                message: msg.message_text,
                room_id: activeRoom.value
            }

            await socket.send(JSON.stringify(body))
        } else {
            messages.value.messages = messages.value.messages.filter(message => message.data.message_code !== msg.message_code)
            await sendFile(msg.message_attachment.file)
        }

    }
}
const removeMessage = (id) => {
    messages.value.messages = messages.value.messages.filter(message => message.data.message_code !== id)
}

const generateCode = () => {
    return Math.floor(Math.random() * (100000000 - 1000000 + 1)) + 1000;
}

const close = () => {
    messages.value = {}
    activeRoom.value = null
    socket.close();
    emit('close')
}

onMounted(async () => {
    socketConnection()
    if(props.setRoomId) {
        openRoom(props.setRoomId)
    }
})
</script>

<style scoped lang="scss">
.chat {
    width: 906px;
    height: 648px;
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;


    &__header {
        position: relative;
        width: 100%;
        height: 44px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid $light-gray-1;
    }

    &__header-title {
        font-size: 16px;
        font-weight: 600;
    }

    &__header-close {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        cursor: pointer;

        img {
            width: 100%;
        }
    }

    &__body {
        display: flex;
    }

    &__chat-list {
        width: 37%;
        height: calc(648px - 44px);
        border-right: 1px solid $light-gray-1;
    }

    &__available-chat {
        width: 100%;
    }

    &__empty {
        width: 63%;
        min-height: 100%;
    }

    &__messages {
        width: 63%;
        min-height: 100%;
    }
}
</style>
